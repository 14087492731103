import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchAppliedOfferService {
  private searchSubject = new BehaviorSubject<string>(''); 
  search$ = this.searchSubject.asObservable();

  updateSearchValue(value: string) {
    this.searchSubject.next(value); 
  }
}
