export const environment = {
    production: false,
    apiHost: 'https://reclutador-dev.partnerdavinci.com',
    serviceAuth: 'auth/api/v1',
    serviceCurriculumVitae: 'curriculum-vitae/api/v1',
    secret_encrypt: 'XEiW2aDXzaMBVDf9oGq8Dt0sBc1nFfXojNPfzrDZijWER3qpHFtrmkYt46',
    role_candidate: 'candidate',
    name_page: 'ActivosColombia',
    id_page_facebook: '239770709223297',
    id_page_instragram: '17841452611495632',
    main_token: 'EAAXvjo6acdoBOZBqhKl12yxnvPw7Y6OtZAhaWUxjIXPWUZBhoGdkrM8AaSJ6GvGrvpFFoGjyVAlRK5Yw5APnXW6Oey7XMUarFNbFOiby9XrjMYv3sV8IwAbPeo7kLH68pY5QEFlc2ZBZA4zGQWDQkdI9MGD9ZCDyAy8IWjHgBl6gpOC2mhaH84ZBnSH',
    maxSizeFile: 10 * 1024 * 1024 // 10 MB
};
