import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarrService {

    private url = `${environment.apiHost}/${environment.serviceCurriculumVitae}/users`;

    constructor(private http: HttpClient) { }

    getProgress(userId: string) {
        return this.http.get<number>(this.url + "/progress/" + userId);
    }

    getDataProgress(userId: string) {
        return this.http.get<any>(this.url + "/data/progress/" + userId);
    }

    updateProgress(userId: string, progress: number, section: string) {
        return this.http.post(this.url + '/progress/' + userId, { progress: progress, section: section });
    }
}
