import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { RequisicionComponent } from './modules/auth/requisicion/requisicion.component';
import { AuthGuard, offerGuard , NoAuthGuard } from './core/auth/guards';
import { curriculumGuard } from './core/auth/guards/curriculum.guard';
import { publicOfferGuard } from './core/auth/guards/public-offer.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'bienvenida'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'bienvenida'},


    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-in/:token', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'requisition', loadComponent : () => RequisicionComponent },
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard, curriculumGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        data: {
            layout: 'dense'
        },
        children: [
            {path: 'registro_datos', loadChildren: () => import('app/modules/secction-register/basic-data/basic-data.routes')},
            {path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes')},
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
            {path: 'bienvenida', loadChildren: () => import('app/modules/secction-register/welcome/welcome.routes')},
            {path: 'registro-academico', loadChildren: () => import('app/modules/academic-register/form-continer/form-continer.routes')},
            {path: 'complementarios', loadChildren: () => import('app/modules/complementary-register/complementary-register-container/complementary-register-container.routes')},
            {path: 'datos-profesionales', loadChildren: () => import('app/modules/profesional-data/profesional-data.routes')},
            {path: 'documentos', loadChildren: () => import('app/modules/documents/documents.routes')},
        ]
    },
    {
        path: 'ofertas',
        canActivate: [publicOfferGuard],
        component: LayoutComponent,
        data: {
            layout: 'dense'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {
                path: '',
                loadChildren:  () => import('app/modules/list-offer/list-offer.routes')
            }
        ]
    },
    {
        path: 'crear-oferta',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'dense'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {
                path: '',
                loadChildren:  () => import('app/modules/create-offer/create-offer.routes')
            }
        ]
    },
    {
        path: 'buscador',
        canActivate: [AuthGuard, publicOfferGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'dense'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {
                path: '',
                loadChildren:  () => import('app/modules/browser/browser-container/browser-container.routes')
            }
        ]
    },
    {
        path: 'hoja-de-vida',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'dense'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {
                path: '',
                loadChildren:  () => import('app/modules/resume/resume.routes')
            }
        ]
    },
    {
        path:'**',
        redirectTo: 'bienvenida'
    }
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'thin',
    //     },
    //     resolve: {
    //         initialData: initialDataResolver
    //     },
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     children: [
    //         { path: 'ofertas', loadChildren: () => import('app/modules/public-offers/public-offers-container/public-offers-container.routes') },
    //         { path: 'postulaciones', loadChildren: () => import('app/modules/offers-applied/offers-applied-container/offers-applied-container.routes') },
    //         { path: 'buscador', loadChildren: () => import('app/modules/browser/browser-container/browser-container.routes') },
    //     ]
    // },


    // routes for public
    // {
    //     path: 'public',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'ofertas', loadChildren: () => import('app/modules/public-offers/public-offers-container/public-offers-container.routes') },
    //     ]
    // },
];
