import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { PermissionValidator } from '../permissions/permissions-validator.service';
import { AccessControl } from '../permissions/interfaces';

export const publicOfferGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const permissionValidator = inject(PermissionValidator);
    const accessControl = inject(AccessControl);

    if(authService.accessToken){
        return authService.verifyPermissions().pipe(map((response) => {
            if (response.permissions) {
                permissionValidator.setPermissions(response);
            }
    
            return true;
        }), catchError(error => {
            console.error(error);
            return of(true);
        }));
    } else {
        return of(true);
    }
};
