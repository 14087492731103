
import {
    HttpRequest,
    HttpEvent,
    HttpHandlerFn,
    HttpResponse,

} from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';
import { ShowErrorComponent } from './dialog/show-error/show-error.component';

export const badRequestInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const dialog = inject(MatDialog);
    const reqClone = req.clone();
    return next(reqClone).pipe(
        tap(
            event => {
                if (event instanceof HttpResponse) {
                    // 200 status code

                }
            },
            error => {
                // Manejo de errores si es necesario
                //console.error(`%cError ${error.status}: ${error.message}\nRequest URL: ${req.url}:`, "color: red; font-weight: bold; border: 1px solid yellow; padding: 30px;font-size: 20px", error.status);
                /*dialog.open(ShowErrorComponent, {
                    data: {
                        message: `Una petición HTTP ha fallado con estado : ${error.status}`
                    },
                    width: '400px',
                })*/
            }
        )
    );
};
