import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterProviderService {
  private filterValues: { [key: string]: any } = {};

  private navigationAppearanceSubject = new BehaviorSubject<'default' | 'dense'>('dense'); 
  navigationAppearance$ = this.navigationAppearanceSubject.asObservable();

  private sideNavSubject = new BehaviorSubject<boolean>(false)
  sideNavSubject$ = this.sideNavSubject.asObservable();

  constructor() {}

  setAllFilters(value): void {
    this.filterValues = value
  }

  getAllFilters(): { [key: string]: any } {
    return this.filterValues;
  }

  clearAllFilters(): void {
    this.filterValues = {};
  }

  closeSideMenu() {
    this.navigationAppearanceSubject.next('dense'); 
  }

  closeFiltersMenu() {
    this.sideNavSubject.next(true); 
  }
}
