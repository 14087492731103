import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Requisition } from 'app/modules/auth/requisicion/services/requisition.singleton';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const requisition = inject(Requisition);

    const requisicion = route.queryParamMap.get('requisicion') ?? null;
    const usuUser = route.queryParamMap.get('usuUsuario') ?? null;
    const redirectTo = route.queryParamMap.get('redirectTo') ?? null;

    return inject(AuthService).check().pipe(
        switchMap((authenticated) => {
            if (usuUser) {
                if (!requisition.existInstance()) {
                    requisition.setInstance(requisicion, usuUser, redirectTo);
                }
                return of(router.parseUrl(`/requisition`));
            }

            if (authenticated && !requisition.existInstance()) {
                return of(router.parseUrl(''));
            }

            return of(true);
        }),
    );
};
