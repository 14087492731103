import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupLoadingComponent } from 'app/shared/popup/popup-loading/popup-loading.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FuseLoadingService {
    private _auto$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    private _mode$: BehaviorSubject<'determinate' | 'indeterminate'> =
        new BehaviorSubject<'determinate' | 'indeterminate'>('indeterminate');
    private _progress$: BehaviorSubject<number | null> = new BehaviorSubject<
        number | null
    >(0);
    private _show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    private _urlMap: Map<string, boolean> = new Map<string, boolean>();
    flag: boolean = false;
    loadingModalActive = false; // Variable global para controlar el modal activo
    private _requestCount = 0;
    private loadingDialogRef: MatDialogRef<any> | null = null;
    private loadingTimeout: any = null;
    private loadingStartTime: number = 0;
    private readonly minLoadingTime = 500; // Tiempo mínimo que el modal debe permanecer visible (en ms)
    private readonly showDelay = 300; // Retraso antes de mostrar el modal (en ms)

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private dialog: MatDialog) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for auto mode
     */
    get auto$(): Observable<boolean> {
        return this._auto$.asObservable();
    }

    /**
     * Getter for mode
     */
    get mode$(): Observable<'determinate' | 'indeterminate'> {
        return this._mode$.asObservable();
    }

    /**
     * Getter for progress
     */
    get progress$(): Observable<number> {
        return this._progress$.asObservable();
    }

    /**
     * Getter for show
     */
    get show$(): Observable<boolean> {
        return this._show$.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the loading bar
     */
    show(): void {
        this._show$.next(true);
    }

    openDialog(): void {
        this._requestCount++;

        // Si el modal ya está abierto o se está programando abrir, no hacemos nada
        if (this.loadingDialogRef || this.loadingTimeout) {
            return;
        }

        // Programamos la apertura del modal después de un retraso
        this.loadingTimeout = setTimeout(() => {
            this.loadingStartTime = Date.now();
            this.loadingDialogRef = this.dialog.open(PopupLoadingComponent, {
                disableClose: true,
                hasBackdrop: true,
            });
            this.loadingTimeout = null;
        }, this.showDelay);
    }

    closeDialog(): void {
        this._requestCount--;

        // Aseguramos que el contador no sea negativo
        if (this._requestCount < 0) {
            this._requestCount = 0;
        }

        // Si aún hay peticiones activas, no cerramos el modal
        if (this._requestCount > 0) {
            return;
        }

        // Si el modal aún no se ha mostrado pero hay un timeout programado, lo cancelamos
        if (this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
            this.loadingTimeout = null;
            return;
        }

        // Calculamos cuánto tiempo ha estado visible el modal
        const elapsedTime = Date.now() - this.loadingStartTime;
        const remainingTime = this.minLoadingTime - elapsedTime;

        // Si el modal ha estado visible por menos del tiempo mínimo, esperamos el tiempo restante
        if (remainingTime > 0) {
            setTimeout(() => {
                this.closeModal();
            }, remainingTime);
        } else {
            this.closeModal();
        }
    }
    private closeModal(): void {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
            this.loadingDialogRef = null;
        }
    }

    /**
     * Hide the loading bar
     */
    hide(): void {
        this._show$.next(false);
    }

    /**
     * Set the auto mode
     *
     * @param value
     */
    setAutoMode(value: boolean): void {
        this._auto$.next(value);
    }

    /**
     * Set the mode
     *
     * @param value
     */
    setMode(value: 'determinate' | 'indeterminate'): void {
        this._mode$.next(value);
    }

    /**
     * Set the progress of the bar manually
     *
     * @param value
     */
    setProgress(value: number): void {
        if (value < 0 || value > 100) {
            console.error('Progress value must be between 0 and 100!');
            return;
        }

        this._progress$.next(value);
    }

    /**
     * Sets the loading status on the given url
     *
     * @param status
     * @param url
     */
    _setLoadingStatus(status: boolean, url: string): void {
        // Return if the url was not provided
        if (!url) {
            console.error('The request URL must be provided!');
            return;
        }

        if (status === true) {
            this._urlMap.set(url, status);
            this._show$.next(true);
        } else if (status === false && this._urlMap.has(url)) {
            this._urlMap.delete(url);
        }

        // Only set the status to 'false' if all outgoing requests are completed
        if (this._urlMap.size === 0) {
            this._show$.next(false);
        }
    }
}
