import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AccessControl } from 'app/core/auth/permissions/interfaces';
import { PermissionValidator } from 'app/core/auth/permissions/permissions-validator.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { FilterProviderService } from 'app/services/filter-provider.service';
import { SearchAppliedOfferService } from 'app/services/search-applied-offer.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
    selector     : 'dense-layout',
    templateUrl  : './dense.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [
        FuseLoadingBarComponent, 
        FuseVerticalNavigationComponent, 
        MatFormFieldModule, 
        MatButtonModule, 
        MatIconModule, 
        MatInputModule,
        LanguagesComponent, 
        FuseFullscreenComponent, 
        SearchComponent, 
        ShortcutsComponent, 
        MessagesComponent, 
        NotificationsComponent, 
        UserComponent, 
        NgIf, 
        RouterOutlet, 
        QuickChatComponent,
        ReactiveFormsModule,
    ],
})
export class DenseLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationAppearance: 'default' | 'dense' = 'dense';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    showSearch = signal(false)
    search = new FormControl('')	

    userLogged: User;
    

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        private searchService: SearchAppliedOfferService,
        public readonly permissionValidator: PermissionValidator,
        public readonly filterProviderService: FilterProviderService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation = navigation;
                this.navigation.default = navigation.default.filter(item => {
                    return this.permissionValidator.can(item.permission);
                });
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');

                // Change the navigation appearance
                this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
            });

        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (user: User) => {
                this.userLogged = user;
            });

        // Mostrar el buscador si está en la ruta de postulaciones
        this.checkRoute(this._router.url); 

        this._router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.checkRoute(event.url);
          }
        });

        this.search.valueChanges.pipe(
            debounceTime(100)
        ) .subscribe(value => {
            this.searchService.updateSearchValue(value);
        })

        this.filterProviderService.navigationAppearance$.subscribe((value) => {
            if(value){
                this.navigationAppearance = value;
            }
        })
    }

    checkRoute(url: string) {
        this.showSearch.set(url.includes('/postulaciones'));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void
    {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');

         this.filterProviderService.closeFiltersMenu()
    }

    setSearch(value: string){
        console.log(value)
        this.search.setValue(value)
    }
}
