import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSlice',
  standalone: true
})
export class CutomSlicePipe implements PipeTransform {

  transform(value: string, min:number= 0, max: number = 30, reverse = false): string {

    if(!value) return;

    if(value.length > max){
      if(reverse){
        return '...' + value.slice(-max);
      } else {
        return value.slice(min, max) + '...'
      }
    }
    return value;
  }

}
