/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // },
    {
        id   : 'home',
        title: 'Hoja de vida',
        type : 'basic',
        isForCandidate: true,
        icon : 'assignment',
        link: '/registro_datos',
        permission: 'createHdv'
    },
    {
        id   : 'create-offer',
        title: 'Creación de ofertas',
        type : 'basic',
        isForCandidate: false,
        icon : 'assignment_turned_in',
        link : '/crear-oferta',
        permission: 'create_offer',
    },
    {
        id   : 'offer-list',
        title: 'Listado de ofertas',
        type : 'basic',
        isForCandidate: false,
        icon : 'assignment',
        link : '/ofertas/seleccion',
        permission: 'view_list_of_all_offers',
    },
    {
        id   : 'empleos',
        title: 'Empleos para ti',
        type : 'basic',
        isForCandidate: true,
        icon : 'heroicons_solid:briefcase',
        link : '/ofertas/publicas',
        permission: 'createHdv'

    },
    {
        id   : 'postulaciones',
        title: 'Tus postulaciones',
        type : 'basic',
        isForCandidate: true,
        icon : 'assignment_turned_in',
        link : '/ofertas/postulaciones',
        permission: 'createHdv'
    },
    {
        id   : 'buscador',
        title: 'Buscar candidatos',
        type : 'basic',
        isForCandidate: false,
        icon : 'heroicons_solid:magnifying-glass',
        link : '/buscador',
        permission: 'search_candidates',
    },
    {
        id   : 'social-media',
        title: 'Publicación en redes sociales',
        type : 'basic',
        isForCandidate: false,
        icon : 'heroicons_solid:share',
        link : '/crear-oferta/list-of-offers-to-publish/publications',
        permission: 'edit_post'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
