import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CurriculumEditorService {
    private candidateId: string;

    setCandidateId(val: string): void {
        this.candidateId = val;
    }

    getCandidateId(): string {
        return this.candidateId;
    }

    clearCandidateId(): void {
        this.candidateId = null;
    }

    isCandidateIdSet(): boolean {
        return this.candidateId !== undefined && this.candidateId !== null && this.candidateId !== '';
    }


}
