import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { PermissionValidator } from '../permissions/permissions-validator.service';
import { AccessControl } from '../permissions/interfaces';
import { CurriculumEditorService } from 'app/services/curriculum-editor.service';
import { UserService } from 'app/core/user/user.service';

export const curriculumGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const permissionValidator = inject(PermissionValidator);
    const accessControl = inject(AccessControl);
    const curriculumEditorService = inject(CurriculumEditorService);
    const userService = inject(UserService);

    return authService.verifyPermissions().pipe(
        switchMap((response) => {
            if (!response.permissions) {
                console.error("user doesn't have permissions ECRR:01");
                authService.signOut();
                router.navigate([permissionValidator.permissions.defaultRoute]);
                return of(false);
            }

            permissionValidator.setPermissions(response);

            if (permissionValidator.can(accessControl.createHdv) || permissionValidator.can(accessControl.editCvCandidate)) {
                const paramsUserId = route.queryParams['userId'];
                if (!paramsUserId && permissionValidator.permissions.name !== 'candidate') {
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return of(false);
                }

                if (paramsUserId) {
                    return userService.getByUserId(paramsUserId).pipe(
                        map(user => {
                            curriculumEditorService.setCandidateId(paramsUserId);
                            return true;
                        }), catchError(() => {
                            router.navigate([permissionValidator.permissions.defaultRoute]);
                            return of(false);
                        })
                    );
                }
                return of(true);
            }

            console.error("user doesn't have permissions or access is disabled ECRR:02");
            router.navigate([permissionValidator.permissions.defaultRoute]);
            return of(false);
        }),
        catchError(error => {
            console.error(error);
            if (error.error.reLoggin) {
                authService.signOut();
                router.navigate(['/sign-in']);
            }
            return of(false);
        })
    );
};
